import { EnvironmentService } from './../services/environment.service';
import { VehicleContextObj, VehicleContextForPrint } from './../models/vehicle-context.model';
import { VehicleDetailsResponseObj } from './../models/maintenance-package.model';
import { VehicleConfigLookupService } from './../services/vehicle-config-lookup.service';
import { TimeBasedService } from './../models/time-based-service.model';
import { PackagesGuard } from './../packages.guard';
import { OwnersLiteratureResponse, OwnersLiteratureArray } from './../models/owners-literature-documents.model';
import {
  CONST_AUDI, CONST_VW, CONST_EN_LANG, CONST_FR_LANG, CONST_IMPORTER_CODE_US, CONST_IMPORTER_CODE_CA, CONST_FR_CA_LOCALE, CONST_EN_US_LOCALE,
  CONST_MAINTENANCE_RESPONSE_TYPE, CONST_EN_US_LANG_CODE, CONST_FR_CA_LANG_CODE,
  CONST_CA_EMISSIONS_DOCTYPE,
  CONST_WARRANTY_DOCTYPE,
  CONST_TIRE_WARRANTY_DOCTYPE,
  CONST_COLLISION_BROCHURE_DOCTYPE,
  CONST_ROADSIDE_BROCHURE_DOCTYPE,
  CONST_OWNERS_MANUAL_DOCTYPE,
  CONST_CA_EMISSIONS_PARTS_DOCTYPE,
  CONST_ORDER_LITERATURE_DOCTYPE,
  CONST_BATTERY_CHARGING_CABLE_MANUAL
} from './../app.constants';
import { CONST_OWNERS_LITERATURE_RESPONSE_TYPE, CONST_BRAND_VW, CONST_BRAND_AUDI } from './../app.constants';
import {
  URL_CUSTOMER_CARE_VW, URL_CUSTOMER_CARE_CAN_FR_VW, URL_CUSTOMER_CARE_CAN_EN_VW, URL_CUSTOMER_CARE_AUDI,
  URL_CUSTOMER_CARE_CAN_FR_AUDI, URL_CUSTOMER_CARE_CAN_EN_AUDI
} from './../app.constants';
import {
  URL_PRIVACY_POLICY_VW, URL_PRIVACY_POLICY_CAN_EN_VW, URL_PRIVACY_POLICY_CAN_FR_VW,
  URL_PRIVACY_POLICY_AUDI, URL_PRIVACY_POLICY_CAN_FR_AUDI, URL_PRIVACY_POLICY_CAN_EN_AUDI,
  URL_CONTACT_US_VW, URL_CONTACT_US_CAN_EN_VW, URL_CONTACT_US_CAN_FR_VW,
  URL_CONTACT_US_AUDI, URL_CONTACT_US_CAN_EN_AUDI, URL_CONTACT_US_CAN_FR_AUDI
} from './../app.constants';
import { Component, OnInit, OnDestroy, ElementRef, ViewEncapsulation, AfterViewInit, ChangeDetectorRef, Renderer2, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { takeWhile, takeUntil, catchError } from 'rxjs/operators';
import { forkJoin, of, Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location, ViewportScroller } from '@angular/common';
import { CloudService } from '../services/cloud.service';
import { ModelYear } from '../models/model-year.model';
import { CarLine } from '../models/car-line.model';
import { ModelTrim } from '../models/model-trim.model';
import { MaintenancePackage } from '../models/maintenance-package.model';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { VinValidators } from '../validators/vin-validators';
import { VehicleConfig } from '../models/vehicle-config.model';
import { EventReport } from '../models/event-report-model';
import { FetchOwnersLiteratureDocumentsError, GetMaintenancePackagesError, GetVehicleConfigError } from '../errors/app-error';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {

  form: FormGroup;
  currentYear = new Date().getFullYear();

  modelYears: ModelYear[] = [];
  carLines: CarLine[] = [];
  modelTrims: ModelTrim[] = [];

  vehicleContextForDisplay: VehicleContextForPrint = {};
  event: EventReport = {};


  alive = true;
  getModelYearErrorOccurred = false;
  spinnerVisible = false;

  userAgentString: string;
  browser: string;
  device: string;
  isMaintenancePackageShown = false;
  isSelectionOptionShown = false;

  errorMessage: string;
  isCustomerCareLinkShown = true;

  isFixedFooter = true;

  isBlueLogoShown = false;
  isFailedSubmission = false;

  displayOdometer: number;
  displayModelYear: number;
  displayCarLine = '';
  displayModelTrimDisc = '';


  selectedModelYear: number = null;
  selectedCarLine = '';
  selectedModelTrim = '';
  selectedModelTrimDesc = '';

  ododmeterUnit = 'MI';
  brand = 'vw';
  importerCode: string;
  language: string;

  odometerValue = 0;

  privacyUrl: string;
  contactUsUrl: string;
  customerCareUrl: string;
  userSelectedLanguage: string;
  orderLiteratureUrl: string;

  isElectricVehicle: boolean;

  searchPageDestroyed$ = new Subject();

  maintenanceResponse: VehicleDetailsResponseObj = null;
  literatureResponse: VehicleDetailsResponseObj = null;
  ownersLiteratureArray: OwnersLiteratureArray[];
  isContentLoaded = false;
  isContentErrorShown = false;
  isLiteratureDisclaimerShown = true;

  isVWLogoHidden = false;

  isAudiBrand: boolean = false;
  isCanadianMarket: boolean = false;

  lastScrollTop = 0;

  public removeEventListener: () => void;

  constructor(private cloudService: CloudService, private formBuilder: FormBuilder, private location: Location,
    private router: Router, private route: ActivatedRoute, private hostElement: ElementRef,
    private changeDetectorRef: ChangeDetectorRef, private renderer: Renderer2,
    public translate: TranslateService, private guard: PackagesGuard, private viewPortScroller: ViewportScroller,
    private vehicleConfigService: VehicleConfigLookupService, private environmentService: EnvironmentService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.form = formBuilder.group({
      vin: ['', Validators.compose([VinValidators.hasCorrectLength, VinValidators.areLastSixDigitsNumeric, VinValidators.isVINPatternValid])],
      //vin: ['', Validators.pattern(/^[A-HJ-NPR-Za-hj-npr-z\d]{8}[\dXx][A-HJ-NPR-Za-hj-npr-z\d]{3}\d{5}$/)],
      odometer: [''],
      modelYear: [''],
      carLine: [''],
      model_trim: ['']
    });
    window.addEventListener('scroll', this.scrolling, false);
  }

  scrolling = (s) => {
    const sc = s.target.scrollTop;
    var st = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    this.isVWLogoHidden = (st > this.lastScrollTop) ? true : false;
    this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    const searchFormElement = document.getElementById('searchFormContent');
    this.isBlueLogoShown = (!_.isNil(searchFormElement) && !_.isNil(searchFormElement.scrollHeight) &&
      this.isContentLoaded && (this.lastScrollTop > searchFormElement.scrollHeight)) ? true : false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.scrolling;
    if (this.isContentLoaded) {
      this.calculateScreenHeight();
    }
  }

  ngOnInit() {
    this.userAgentString = navigator.userAgent;
    this.device = this.findDevice();
    this.browser = this.findBrowser(this.userAgentString);
    //console.log('Browser Type >>>>' + this.browser);    
    //console.log('Device Type >>>>' + this.device);
    this.event.entry_point_url = location.host;											   
    this.vin.valueChanges.subscribe(() => {
      if (this.vin.invalid) {
        this.clearContentArea();
      }
    });

    console.log('Vehicle Context  >>> ', this.vehicleConfigService.isVehicleDetailInContext());
    if (this.vehicleConfigService.isVehicleDetailInContext()) {
      // PROCESS THE VEHICLE
      this.processVehicleInContext();
      this.cloudService.saveAuditDetails(this.event, 'Click_Page_Load_Site_Visit');
    } else {
      // Initial Load
      this.initVariables();
      this.processRoute();
      this.onPageLoaded();
    }

    this.route.queryParams.subscribe(params => {
      let vin = params['vin'];
       if(vin) {
        this.form.controls.vin.patchValue(vin);
        this.vin.setValue(vin);
        this.searchForPackages();
       }      
    });

  }

  onPageLoaded(){
    //this.buildBaseEvent();
    this.event.entry_point_url = location.host;
    this.event.source = this.browser;
    this.event.source_type = this.device;
    this.event.user_brand = this.brand;
    this.event.user_brand = this.brand;
    this.event.user_importer_code = this.importerCode;
    this.event.vehicle_brand = this.brand;
    this.event.vehicle_importer_code = this.importerCode;
    if (this.importerCode == '444') {
      this.event.language_code = this.userSelectedLanguage + "-US";
    } else {
      this.event.language_code = this.userSelectedLanguage + "-CA";
    }
    this.event.model_code = "ABCDEF";
    this.event.model_year = 9999;
    this.event.model_name = 'NA';
    this.event.vehicle_trim = 'NA';
    this.event.vin='NA';
    this.cloudService.saveAuditDetails(this.event, 'Click_Page_Load_Site_Visit');
  }

  findBrowser(userAgentString: string) {
    console.log("Browser Type - UserAgent String" + userAgentString)
    if (this.device.indexOf("Mobile") > -1) {
      // Detect Chrome
      if (userAgentString.indexOf("Edg") > -1) { return 'Edge' }

      // Detect Chrome
      if (userAgentString.indexOf("CriOS") > -1) { return 'Chrome' }

      // Detect Firefox
      if (userAgentString.indexOf("Firefox") > -1) { return "Firefox" }

      // Detect Internet Explorer
      if (userAgentString.indexOf("MSIE") > -1 ||
        userAgentString.indexOf("rv:") > -1) { return "IExplorer" }

      // Detect Safari
      if (userAgentString.indexOf("Safari") > -1) { return "Safari" }

      // Detect Opera
      if (userAgentString.indexOf("OP") > -1) { return "Opera" };
    } else {
      // Detect Chrome
      if (userAgentString.indexOf("Edg") > -1) { return 'Edge' }

      // Detect Chrome
      if (userAgentString.indexOf("Chrome") > -1) { return 'Chrome' }

      // Detect Firefox
      if (userAgentString.indexOf("Firefox") > -1) { return "Firefox" }

      // Detect Internet Explorer
      if (userAgentString.indexOf("MSIE") > -1 ||
        userAgentString.indexOf("rv:") > -1) { return "IExplorer" }

      // Detect Safari
      if (userAgentString.indexOf("Safari") > -1) { return "Safari" }

      // Detect Opera
      if (userAgentString.indexOf("OP") > -1) { return "Opera" };
    }

    return 'Other'

  }

  findDevice() {
    var ua = navigator.userAgent;
    // Detect Chrome
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mac OS X|Mobile|mobile|CriOS/i.test(ua)) {
      return 'Mobile'
    } else {
      return 'Desktop'
    }

  }
  initVariables() {
    this.isFailedSubmission = false;
    this.isContentLoaded = false;
    this.isElectricVehicle = false;
    this.isContentErrorShown = false;
    this.isLiteratureDisclaimerShown = true;
    this.vehicleContextForDisplay = {};
  }

  processRoute() {
    const storedBrand = localStorage.getItem('CF_UPG_BRAND');
    this.brand = !_.isNil(storedBrand) ? storedBrand : CONST_BRAND_VW;
    this.importerCode = CONST_IMPORTER_CODE_US;
    this.language = CONST_EN_LANG;
    if (this.environmentService.isDevEnvironment() || this.environmentService.isQAEnvironment()) {
      this.route.params.pipe(takeUntil(this.searchPageDestroyed$)).subscribe((params) => {
        this.environmentService.setRouteParams(params);
        if (!_.isNil(params)) {

          this.brand = (!_.isNil(params.brand) && (params.brand.toLowerCase() === CONST_VW)) ? CONST_BRAND_VW :
            (!_.isNil(params.brand) && (params.brand.toLowerCase() === CONST_AUDI)) ? CONST_BRAND_AUDI : this.brand;

          this.importerCode = (!_.isNil(params.importerCode) && (params.importerCode === CONST_IMPORTER_CODE_US ||
            params.importerCode === CONST_IMPORTER_CODE_CA)) ? (params.importerCode) : CONST_IMPORTER_CODE_US;

          this.language = ((this.importerCode === CONST_IMPORTER_CODE_CA) && !_.isNil(params.languageCode) &&
            (params.languageCode.toLowerCase() === CONST_FR_LANG)) ? CONST_FR_LANG : this.language;

        }
        if (this.environmentService.isQaAudiMaintenanceOrigin()) {
          this.brand = CONST_BRAND_AUDI;
          this.importerCode = CONST_IMPORTER_CODE_US;
        }
        this.vehicleConfigService.resetVariables(this.brand, this.importerCode);
        this.environmentService.updateEnvironmentVariablesInDevOrQA(this.brand, this.importerCode, this.language);
        this.initDataLoad();
      });
    } else {
      this.environmentService.findEnvironmentVariablesFromOrigin();
      this.brand = this.environmentService.getBrand();
      this.importerCode = this.environmentService.getImporterCode();
      this.vehicleConfigService.resetVariables(this.brand, this.importerCode);
      if (this.importerCode === CONST_IMPORTER_CODE_CA) {
        this.route.params.pipe(takeUntil(this.searchPageDestroyed$)).subscribe((params) => {
          if (!_.isNil(params)) {
            this.language = (!_.isNil(params.languageCode) && params.languageCode.toLowerCase() === CONST_FR_LANG) ? CONST_FR_LANG : CONST_EN_LANG;
            this.environmentService.applyAppTheme(this.language);
            this.initDataLoad();
          }
        });
      } else {
        this.environmentService.applyAppTheme();
        this.initDataLoad();
      }
    }
  }

  onLanguageSelectionChanged(newLanguage: string) {

    this.onLanguageToggleSelect(newLanguage);
    
    if (this.userSelectedLanguage === newLanguage) {
      return;
    }
    this.userSelectedLanguage = newLanguage;
    this.translate.use(newLanguage);

    this.environmentService.setLanguage(newLanguage);
    this.environmentService.setLanguageCode((newLanguage === CONST_FR_LANG) ? CONST_FR_CA_LANG_CODE : CONST_EN_US_LANG_CODE);
    this.environmentService.setLocale((newLanguage === CONST_FR_LANG) ? CONST_FR_CA_LOCALE : CONST_EN_US_LOCALE);

    this.updateLinks();
    this.fetchModelYears();
    this.resetDropdownValues();

    if ((this.selectedModelYear && this.selectedCarLine && this.selectedModelTrim) || (!_.isNil(this.vin) && this.vin.value.length > 0)) {
      this.searchForPackages();
    } else {
      this.clearVariables();
    }
  }

  onLanguageToggleSelect(type: string){
    this.event.entry_point_url = location.host;
    this.event.source = this.browser;
    this.event.source_type = this.device;
    this.event.user_brand = this.brand;
    this.event.user_brand = this.brand;
    this.event.user_importer_code = this.importerCode;
    this.event.vehicle_brand = this.brand;
    this.event.vehicle_importer_code = this.importerCode;
    if (this.importerCode == '444') {
      this.event.language_code = this.userSelectedLanguage + "-US";
    } else {
      this.event.language_code = type + "-CA";
    }
    //if(!_.isNil(this.event.vin) || !_.isNil(this.event.vehicle_trim)){
    if(!_.isNil(this.event.vin)){
      //VINorVehicleBuildIsSelected 
    }else{
      this.event.model_code = "ABCDEF";
      this.event.model_year = 9999;
      this.event.model_name = 'NA';
      this.event.vehicle_trim = 'NA';
      this.event.vin='NA';
    }
    this.cloudService.saveAuditDetails(this.event, 'Click_Language_Toggle');
  }

  initDataLoad() {
    this.userSelectedLanguage = this.language;
    this.isAudiBrand = this.environmentService.isAudiBrand();
    this.isCanadianMarket = this.environmentService.isCanadianMarket();

    this.translate.use(this.language);
    this.updateLinks();
    this.fetchModelYears();
  }

  loadContextVariables() {
    this.brand = this.environmentService.getBrand();
    this.importerCode = this.environmentService.getImporterCode();
    this.userSelectedLanguage = this.environmentService.getLanguage();
    this.isAudiBrand = this.environmentService.isAudiBrand();
    this.isCanadianMarket = this.environmentService.isCanadianMarket();
    this.isElectricVehicle = this.vehicleConfigService.isVehicleElectric();
    this.ownersLiteratureArray = this.vehicleConfigService.getOwnersLiteratureArray();
    this.updateLiteratureArrayTranslations();
    this.maintenanceResponse = this.vehicleConfigService.getMaintenanceResponse();
    this.literatureResponse = this.vehicleConfigService.getLiteratureResponse();
    this.orderLiteratureUrl = this.vehicleConfigService.getOrderLiteratureUrl();
    this.modelYears = this.cloudService.getVehicleContextModelYears();
    this.odometer.setValue('');
    this.event = this.vehicleConfigService.getEventReport();
  }

  clearVariables() {
    this.isFailedSubmission = false;
    this.isContentLoaded = false;
    this.isCustomerCareLinkShown = false;
    this.isContentErrorShown = false;
    this.isElectricVehicle = false;
    this.isLiteratureDisclaimerShown = true;

    this.environmentService.updateBackgroundImage();
    this.vehicleConfigService.clearVariables();
    this.odometer.setValue('');
    //this.clearEventVariables();
  }

  clearEventVariables(){
    this.event.vin=null;
  }  

  processVehicleInContext() {
    this.loadContextVariables();
    this.updateLinks();
    const vehicleContext = this.vehicleConfigService.getVehicleContext();
    this.vehicleContextForDisplay = _.cloneDeep(this.vehicleConfigService.getVehicleContextForPrint());

    if (!_.isNil(vehicleContext) && !_.isNil(vehicleContext.vin)) {
      this.vin.setValue(vehicleContext.vin);
      this.odometer.setValue(vehicleContext.odometer.toLocaleString());
      this.isContentLoaded = true;
      this.environmentService.removeBackgroundImage();
      this.displayOdometer = vehicleContext.odometer;
    } else if (!_.isNil(vehicleContext) && !_.isNil(vehicleContext.modelYear) && !_.isNil(vehicleContext.carLine) && !_.isNil(vehicleContext.modelTrim)) {
      this.modelYears = this.cloudService.getVehicleContextModelYears();
      this.form.patchValue({ modelYear: vehicleContext.modelYear });
      this.selectedModelYear = vehicleContext.modelYear;
      this.displayModelYear = vehicleContext.modelYear;

      this.carLines = this.cloudService.getVehicleContextCarLines();
      this.form.patchValue({ carLine: vehicleContext.carLine });
      this.selectedCarLine = vehicleContext.carLine;
      this.displayCarLine = vehicleContext.carLine;

      this.modelTrims = this.cloudService.getVehicleContextModelTrims();
      this.form.patchValue({ model_trim: vehicleContext.modelTrim });
      this.selectedModelTrim = vehicleContext.modelTrim;

      const trim = this.modelTrims.find(model => model.salesModelCode === this.selectedModelTrim);
      this.selectedModelTrimDesc = (!_.isNil(trim) && !_.isNil(trim.modelTrimDescription)) ? trim.modelTrimDescription : null;
      this.displayModelTrimDisc = (!_.isNil(trim) && !_.isNil(trim.modelTrimDescription)) ? trim.modelTrimDescription : null;

      this.odometer.setValue(vehicleContext.odometer.toLocaleString());
      this.displayOdometer = vehicleContext.odometer;

      this.isContentLoaded = true;
      this.environmentService.removeBackgroundImage();
    } else {
      this.vin.setValue('');
      this.odometer.setValue(vehicleContext.odometer.toLocaleString());
      this.selectedModelYear = vehicleContext.modelYear;
      this.displayModelYear = vehicleContext.modelYear;
      this.isContentLoaded = false;
      this.environmentService.updateBackgroundImage();
    }
    this.cloudService.setIsVehicleInContext(false);
    this.vehicleConfigService.setVehicleInContext(false);
    this.isLiteratureDisclaimerShown = this.vehicleConfigService.checkDocsAvailabilityForDisclaimer();
    this.isSearchButtonEnabled();
  }

  searchForPackages() {
    this.isFailedSubmission = false;
    this.event = {};
    this.odometerValue = ((!_.isNil(this.odometer) && !_.isNil(this.odometer.value)) ? (+this.odometer.value.toString().replace(/[^\d]/g, '')) : 0);
    this.resetVariables();
    const vehicleContext = this.getVehicleContext();
    this.cloudService.setPackageValues(vehicleContext);
    this.displayOdometer = this.odometer.value;
    this.displayCarLine = this.selectedCarLine.toString();
    this.displayModelYear = this.selectedModelYear;
    this.displayModelTrimDisc = this.selectedModelTrimDesc.toString();

    this.spinnerVisible = true;
    this.buildBaseEvent();
    if (!this.isCanadianMarket) {
      this.callServicesForUSMarket(vehicleContext, this.event);
    } else {
      this.callServicesForCanadaMarket(vehicleContext, this.event);
    }

    if (!_.isNil(vehicleContext.vin)) {

    } else {
      this.buildEvent();
    }
  }


  buildEvent() {
    this.event.entry_point_url = location.host;
    this.event.source = this.browser;
    this.event.source_type = this.device;
    if (this.importerCode == '444') {
      this.event.language_code = this.userSelectedLanguage + "-US";
    } else {
      this.event.language_code = this.userSelectedLanguage + "-CA";
    }
    this.event.user_brand = this.brand;
    this.event.user_importer_code = this.importerCode;
    this.event.vehicle_brand = this.brand;
    this.event.vehicle_importer_code = this.importerCode;
    this.event.vin = this.getVehicleContext().vin;
    this.event.vehicle_trim = this.vehicleConfigService.getVehicleContextForPrint().trimDescription;
    this.event.model_year = this.vehicleConfigService.getVehicleContextForPrint().model_year;
    this.event.model_name = this.vehicleConfigService.getVehicleContextForPrint().carLine;
    this.event.model_code = this.selectedModelTrim;
    this.event.mileage = this.odometerValue;

    this.vehicleConfigService.setEventReport(this.event);
    if (!this.isFailedSubmission) {
      this.cloudService.saveAuditDetails(this.event, 'Successful_Vehicle_Build_Submission');
      this.cloudService.saveAuditDetails(this.event, 'Successful_Submission');
    }
  }

  buildBaseEvent() {
    this.event.entry_point_url = location.host;
    this.event.source = this.browser;
    this.event.source_type = this.device;
    if (this.importerCode == '444') {
      this.event.language_code = this.userSelectedLanguage + "-US";
    } else {
      this.event.language_code = this.userSelectedLanguage + "-CA";
    }
    this.event.user_brand = this.brand;
    this.event.user_importer_code = this.importerCode;
    this.event.vehicle_brand = this.brand;
    this.event.vehicle_importer_code = this.importerCode;
    this.event.vin = this.getVehicleContext().vin;
    this.event.mileage = this.odometerValue;
    this.vehicleConfigService.setEventReport(this.event);
  }
  resetVariables() {
    this.isContentLoaded = false;
    this.isContentErrorShown = false;
    this.isElectricVehicle = false;
    this.maintenanceResponse = null;
    this.literatureResponse = null;
    this.ownersLiteratureArray = [];
    this.environmentService.updateBackgroundImage();
    this.vehicleConfigService.resetVariables(this.brand, this.importerCode);
    this.vehicleContextForDisplay = {};
  }

  updateLiteratureArrayTranslations() {
    if (!_.isNil(this.ownersLiteratureArray) && this.ownersLiteratureArray.length > 0) {
      this.ownersLiteratureArray.forEach((literature) => {
        literature.headerMessage = (literature.headerMessage.length > 0) ? this.translate.instant(literature.headerMessage) : literature.headerMessage;
        literature.errorMessage = (literature.errorMessage.length > 0) ? this.translate.instant(literature.errorMessage) : literature.errorMessage;
      });
    }
  }

  getVehicleContext(): VehicleContextObj {
    let vehicleContext: VehicleContextObj = {};
    let vehicleContextForPrint: VehicleContextForPrint = {};
    this.vehicleContextForDisplay = {};

    vehicleContext.odometer = this.odometerValue;
    vehicleContextForPrint.mileage = (!_.isNil(this.odometer) && !_.isNil(this.odometer.value) && (this.odometer.value.length > 0) && !_.isNil(this.odometerValue)) ? this.odometerValue.toLocaleString() : '';

    if (this.selectedModelYear && this.selectedCarLine && this.selectedModelTrim) {
      vehicleContext.modelYear = this.selectedModelYear;
      vehicleContext.carLine = this.selectedCarLine;
      vehicleContext.modelTrim = this.selectedModelTrim;

      const trim = this.modelTrims.find(model => model.salesModelCode === this.selectedModelTrim);
      vehicleContextForPrint.trimDescription = (!_.isNil(trim) && !_.isNil(trim.modelTrimDescription)) ? trim.modelTrimDescription : null;
      vehicleContextForPrint.model_year = this.selectedModelYear;
      vehicleContextForPrint.carLine = this.selectedCarLine;
      vehicleContextForPrint.model = this.selectedCarLine;
    } else if (this.vin) {
      if (this.vin.value) {
        vehicleContextForPrint.vin = this.vin.value.toUpperCase();
        vehicleContext.vin = this.vin.value.toUpperCase();
      }
    }

    this.vehicleConfigService.setVehicleContext(vehicleContext);
    this.vehicleConfigService.setVehicleContextForPrint(vehicleContextForPrint);
    this.vehicleContextForDisplay = _.cloneDeep(vehicleContextForPrint);

    return vehicleContext;
  }

  updateLinks() {
    if (!this.isAudiBrand) {
      if (this.importerCode === CONST_IMPORTER_CODE_US) {
        this.privacyUrl = URL_PRIVACY_POLICY_VW;
        this.contactUsUrl = URL_CONTACT_US_VW;
        this.customerCareUrl = URL_CUSTOMER_CARE_VW;
      } else {
        this.privacyUrl = (this.userSelectedLanguage === CONST_FR_LANG) ? URL_PRIVACY_POLICY_CAN_FR_VW : URL_PRIVACY_POLICY_CAN_EN_VW;
        this.contactUsUrl = (this.userSelectedLanguage === CONST_FR_LANG) ? URL_CONTACT_US_CAN_FR_VW : URL_CONTACT_US_CAN_EN_VW;
        this.customerCareUrl = (this.userSelectedLanguage === CONST_FR_LANG) ? URL_CUSTOMER_CARE_CAN_FR_VW : URL_CUSTOMER_CARE_CAN_EN_VW;
      }
    } else {
      if (this.importerCode === CONST_IMPORTER_CODE_US) {
        this.privacyUrl = URL_PRIVACY_POLICY_AUDI;
        this.contactUsUrl = URL_CONTACT_US_AUDI;
        this.customerCareUrl = URL_CUSTOMER_CARE_AUDI;
      } else {
        this.privacyUrl = (this.userSelectedLanguage === CONST_FR_LANG) ? URL_PRIVACY_POLICY_CAN_FR_AUDI : URL_PRIVACY_POLICY_CAN_EN_AUDI;
        this.contactUsUrl = (this.userSelectedLanguage === CONST_FR_LANG) ? URL_CONTACT_US_CAN_FR_AUDI : URL_CONTACT_US_CAN_EN_AUDI;
        this.customerCareUrl = (this.userSelectedLanguage === CONST_FR_LANG) ? URL_CUSTOMER_CARE_CAN_FR_AUDI : URL_CUSTOMER_CARE_CAN_EN_AUDI;
      }
    }
    this.environmentService.setPrivacyUrl(this.privacyUrl);
    this.environmentService.setContactUsUrl(this.contactUsUrl);
  }

  updateVehicleContextForDisplay(vehicleContext: VehicleContextObj) {
    if (!_.isNil(vehicleContext.vin)) {
      this.cloudService.getModelTrim(this.vehicleContextForDisplay.model_year, this.vehicleContextForDisplay.model).subscribe((modelTrim: ModelTrim) => {
        this.vehicleContextForDisplay.trimDescription = (!_.isNil(modelTrim)) ? _.cloneDeep(modelTrim.modelTrimDescription) : '';
        this.vehicleConfigService.setVehicleContextForPrint(_.cloneDeep(this.vehicleContextForDisplay));

        this.event.model_code = this.vehicleContextForDisplay.model;
        this.event.model_year = this.vehicleContextForDisplay.model_year;
        this.event.model_name = this.vehicleContextForDisplay.carLine;
        this.event.vehicle_trim = this.vehicleContextForDisplay.trimDescription;

        this.vehicleConfigService.setEventReport(this.event);
        this.vehicleConfigService.updateVehicleDetailsResponse(this.ownersLiteratureArray, CONST_OWNERS_LITERATURE_RESPONSE_TYPE, true, false, true);
        //if(!this.isFailedSubmission){
        this.cloudService.saveAuditDetails(this.event, 'Successful_VIN_Submission');
        this.cloudService.saveAuditDetails(this.event, 'Successful_Submission');
        //}

      }, (error) => {
        console.log('Error While Fetching Model Trim Description >> ', error);
      });
    }
  }

  callServicesForCanadaMarket(vehicleContext: VehicleContextObj, event: EventReport) {
    if (!_.isNil(vehicleContext.vin)) {
      forkJoin([this.cloudService.fetchMaintenancePacakges(vehicleContext).pipe(catchError(e => of(e))),
      this.cloudService.fetchOwnersLiteratureDocuments(vehicleContext).pipe(catchError(e => of(e))),
      this.cloudService.fetchWhiteListData().pipe(catchError(x => of(null))),
      this.cloudService.fetchVehicleConfigurationInformation(vehicleContext.vin).pipe(catchError(x => of(null)))])
        .pipe(takeUntil(this.searchPageDestroyed$)).subscribe(([maintenancePackageResponse, ownersLiteratureResponse, whiteListDataResponse, vehicleInfoResponse]) => {
          this.processWhiteListDataResponse(whiteListDataResponse);
          this.processVehicleConfigInfoResponse(vehicleInfoResponse, vehicleContext);
          this.processMaintenancePackageResponse(maintenancePackageResponse, vehicleContext, event);
          this.processOwnersLiteratureResponse(ownersLiteratureResponse);
        });
    } else {
      forkJoin([this.cloudService.fetchMaintenancePacakges(vehicleContext).pipe(catchError(e => of(e))),
      this.cloudService.fetchOwnersLiteratureDocuments(vehicleContext).pipe(catchError(e => of(e))),
      this.cloudService.fetchWhiteListData().pipe(catchError(x => of(null)))])
        .pipe(takeUntil(this.searchPageDestroyed$)).subscribe(([maintenancePackageResponse, ownersLiteratureResponse, whiteListDataResponse]) => {
          this.processWhiteListDataResponse(whiteListDataResponse);
          this.processMaintenancePackageResponse(maintenancePackageResponse, vehicleContext, event);
          this.processOwnersLiteratureResponse(ownersLiteratureResponse);
        });
    }
  }

  callServicesForUSMarket(vehicleContext: VehicleContextObj, event: EventReport) {
    console.log(vehicleContext);
    if (!_.isNil(vehicleContext.vin)) {
      forkJoin([this.cloudService.fetchMaintenancePacakges(vehicleContext).pipe(catchError(e => of(e))),
      this.cloudService.fetchOwnersLiteratureDocuments(vehicleContext).pipe(catchError(e => of(e))),
      this.cloudService.fetchWhiteListData().pipe(catchError(x => of(null))),
      this.cloudService.fetchVehicleConfigurationInformation(vehicleContext.vin).pipe(catchError(x => of(null)))])
        .pipe(takeUntil(this.searchPageDestroyed$)).subscribe(([maintenancePackageResponse, ownersLiteratureResponse, whiteListDataResponse, vehicleInfoResponse]) => {
          this.processWhiteListDataResponse(whiteListDataResponse);
          this.processVehicleConfigInfoResponse(vehicleInfoResponse, vehicleContext);
          this.processMaintenancePackageResponse(maintenancePackageResponse, vehicleContext, event);
          this.processOwnersLiteratureResponse(ownersLiteratureResponse);
        });
    } else {
      forkJoin([this.cloudService.fetchMaintenancePacakges(vehicleContext).pipe(catchError(e => of(e))),
      this.cloudService.fetchOwnersLiteratureDocuments(vehicleContext).pipe(catchError(e => of(e))),
      this.cloudService.fetchWhiteListData().pipe(catchError(x => of(null)))])
        .pipe(takeUntil(this.searchPageDestroyed$)).subscribe(([maintenancePackageResponse, ownersLiteratureResponse, whiteListDataResponse]) => {
          this.processWhiteListDataResponse(whiteListDataResponse);
          this.processMaintenancePackageResponse(maintenancePackageResponse, vehicleContext, event);
          this.processOwnersLiteratureResponse(ownersLiteratureResponse);
        });
    }
  }

  processMaintenancePackageResponse(maintenancePackagesResponse: any, vehicleContext: VehicleContextObj, event: EventReport) {
    console.log(maintenancePackagesResponse);
    if (!_.isNil(maintenancePackagesResponse) && maintenancePackagesResponse instanceof GetMaintenancePackagesError) {
      const serviceError = (!_.isNil(maintenancePackagesResponse.originalError) && !_.isNil(maintenancePackagesResponse.originalError['error'])) ? maintenancePackagesResponse.originalError['error'] : {};
      this.vehicleConfigService.updateVehicleDetailsResponse(serviceError, CONST_MAINTENANCE_RESPONSE_TYPE, true, true, false);
      this.displayVehicleResponse();
    } else {
      if (!_.isNil(maintenancePackagesResponse)) {
        const timeBasedServices: TimeBasedService[] = (!_.isNil(maintenancePackagesResponse) && !_.isNil(maintenancePackagesResponse['time_based_services'])) ? maintenancePackagesResponse['time_based_services'] : [];
        this.vehicleConfigService.setTimeBasedServices(timeBasedServices);
        const maintenancePackages: MaintenancePackage[] = (!_.isNil(maintenancePackagesResponse) && !_.isNil(maintenancePackagesResponse['packages'])) ? maintenancePackagesResponse['packages'] : [];
        if (maintenancePackages.length <= 0) {
          if (!_.isNil(vehicleContext.vin)) {
            this.cloudService.getModelTrim(this.vehicleContextForDisplay.model_year, this.vehicleContextForDisplay.model).subscribe((modelTrim: ModelTrim) => {
              this.event.model_code = this.vehicleContextForDisplay.model;
              this.event.model_year = this.vehicleContextForDisplay.model_year;
              this.event.model_name = this.vehicleContextForDisplay.carLine;
              this.event.vehicle_trim = this.vehicleContextForDisplay.trimDescription;
              this.vehicleConfigService.setEventReport(this.event);
              this.cloudService.saveAuditDetails(this.event, "No_Maintenance_Error");
            }, (error) => {
              console.log('Error While Fetching Model Trim Description >> ', error);
            });
          } else {
            this.cloudService.saveAuditDetails(this.event, "No_Maintenance_Error");
          }
        };
        this.vehicleConfigService.updateVehicleDetailsResponse(maintenancePackages, CONST_MAINTENANCE_RESPONSE_TYPE, true, false, false);
        this.displayVehicleResponse();
      }
    }
  }

  processOwnersLiteratureResponse(ownersliteratureResponse: any) {
    console.log(ownersliteratureResponse);
    if (!_.isNil(ownersliteratureResponse) && (ownersliteratureResponse instanceof FetchOwnersLiteratureDocumentsError)) {
      const serviceError = (!_.isNil(ownersliteratureResponse.originalError) && !_.isNil(ownersliteratureResponse.originalError['error'])) ? ownersliteratureResponse.originalError['error'] : {};
      this.vehicleConfigService.updateVehicleDetailsResponse(serviceError, CONST_OWNERS_LITERATURE_RESPONSE_TYPE, true, true, false);
      this.displayVehicleResponse();
    } else {
      if (!_.isNil(ownersliteratureResponse)) {
        const modelYear = (!_.isNil(ownersliteratureResponse.request) && !_.isNil(ownersliteratureResponse.request.model_year)) ? ownersliteratureResponse.request.model_year : 0;
        const modelCode = (!_.isNil(ownersliteratureResponse.request) && !_.isNil(ownersliteratureResponse.request.model_code)) ? ownersliteratureResponse.request.model_code.substring(0, 3) : '';
        this.vehicleConfigService.setOwnersLitratureResponseForAbridge(ownersliteratureResponse);
        //this.vehicleConfigService.isWhiteListModelUpdateErrorMessage(this.brand, this.importerCode, modelCode, modelYear);
        this.vehicleConfigService.isWhiteListModelDisplayBatteryLink(this.brand, this.importerCode, modelCode, modelYear);
        this.vehicleConfigService.updateOwnersLiteratureArray(ownersliteratureResponse, modelYear, modelCode, this.vin.value, this.importerCode);
        this.ownersLiteratureArray = this.vehicleConfigService.getOwnersLiteratureArray();
        this.updateLiteratureArrayTranslations();
        this.vehicleConfigService.updateVehicleDetailsResponse(this.ownersLiteratureArray, CONST_OWNERS_LITERATURE_RESPONSE_TYPE, true, false, true);
        this.isElectricVehicle = this.vehicleConfigService.isVehicleElectric();
        this.orderLiteratureUrl = this.vehicleConfigService.getOrderLiteratureUrl();
        this.isLiteratureDisclaimerShown = this.vehicleConfigService.checkDocsAvailabilityForDisclaimer();
        this.displayVehicleResponse();
      }
    }
  }

  documentTypes: string[] = [
    CONST_OWNERS_MANUAL_DOCTYPE,
    CONST_WARRANTY_DOCTYPE,
    CONST_TIRE_WARRANTY_DOCTYPE,
    CONST_COLLISION_BROCHURE_DOCTYPE,
    CONST_CA_EMISSIONS_PARTS_DOCTYPE,
    CONST_CA_EMISSIONS_DOCTYPE,
    CONST_ROADSIDE_BROCHURE_DOCTYPE,
    CONST_ORDER_LITERATURE_DOCTYPE
  ];

  documentTypesCanada: string[] = [
    CONST_OWNERS_MANUAL_DOCTYPE,
    CONST_WARRANTY_DOCTYPE,
    CONST_TIRE_WARRANTY_DOCTYPE,
    CONST_COLLISION_BROCHURE_DOCTYPE,
    CONST_ORDER_LITERATURE_DOCTYPE
  ];

  electricDocumentTypes: string[] = [
    CONST_OWNERS_MANUAL_DOCTYPE,
    CONST_BATTERY_CHARGING_CABLE_MANUAL,
    CONST_WARRANTY_DOCTYPE,
    CONST_TIRE_WARRANTY_DOCTYPE,
    CONST_COLLISION_BROCHURE_DOCTYPE,
    CONST_ROADSIDE_BROCHURE_DOCTYPE,
    CONST_ORDER_LITERATURE_DOCTYPE
  ];

  electricDocumentTypesCanada: string[] = [
    CONST_OWNERS_MANUAL_DOCTYPE,
    CONST_BATTERY_CHARGING_CABLE_MANUAL,
    CONST_WARRANTY_DOCTYPE,
    CONST_TIRE_WARRANTY_DOCTYPE,
    CONST_COLLISION_BROCHURE_DOCTYPE,
    CONST_ORDER_LITERATURE_DOCTYPE
  ];

  get documentTypesInRows(): string[][] {
    return [
      this.documentTypes.slice(0, 4),
      this.documentTypes.slice(4, 8)
    ];
  }

  get documentTypesInRowsinIphone(): string[][] {
    let documentTypes = [];
    if (this.documentTypes.length == 7 && this.isMobile()) {
      documentTypes = [
       this.documentTypes.slice(0, 2),
       this.documentTypes.slice(2, 4),
       this.documentTypes.slice(4, 6),
       this.documentTypes.slice(6, 7),
     ];
   } else {
     documentTypes = [
      this.documentTypes.slice(0, 4),
      this.documentTypes.slice(4, 8)
     ];
   }
   return documentTypes;
  }

  get electricDocumentTypesInRows(): string[][] {
    return [
      this.electricDocumentTypes.slice(0, 4),
      this.electricDocumentTypes.slice(4, 7)
    ];
  }

  get documentTypesForCanadaInRows(): string[][] {
    return [
      this.documentTypesCanada.slice(0, 3),
      this.documentTypesCanada.slice(3, 5)
    ];
  }

  get documentTypesForCanadaInRowsinIphone(): string[][] {
    let documentTypes = [];
    if ((this.documentTypesCanada.length == 5 || this.documentTypesCanada.length == 7) && this.isMobile()) {
       documentTypes = [
        this.documentTypesCanada.slice(0, 2),
        this.documentTypesCanada.slice(2, 4),
        this.documentTypesCanada.slice(4, 5)
      ];
    } else {
      documentTypes = [
        this.documentTypesCanada.slice(0, 3),
        this.documentTypesCanada.slice(3, 5)
      ];
    }
    return documentTypes;
  }

  // get documentTypesInRowsInIphone(): string[][] {
  //   return [
  //     this.documentTypes.slice(0, 2),
  //     this.documentTypes.slice(2, 4),
  //     this.documentTypes.slice(4, 6)
  //   ];
  // }

  get electricDocumentForCanadaTypesInRows(): string[][] {
    return [
      this.electricDocumentTypesCanada.slice(0, 3),
      this.electricDocumentTypesCanada.slice(3, 6)
    ];
  }

  
  isIphone() {
    // return window.screen.width <= 530;
    return /iPhone/.test(navigator.userAgent) && !window['MSStream'];
  }

  isMobile() {
    return window.screen.width <= 530;
  }
  
  getLiterature(docType: string): any {
    return this.ownersLiteratureArray.find(item => item.docType === docType);
  }

  isDataAvailable(docType: string): boolean {
    const literature = this.getLiterature(docType);
    var response = literature ? literature.isDataAvailable : false;
    // console.log('isdataavailable = ' + response);
    return response;
  }

  checkDocTypes() {
    const docTypes = this.isCanadianMarket ? 
    (this.isElectricVehicle ? this.electricDocumentTypesCanada : this.documentTypesCanada) :
    (this.isElectricVehicle ? this.electricDocumentTypes : this.documentTypes);
    const unavailableDocs = docTypes.filter(type => !this.isDataAvailable(type));
    if (unavailableDocs && unavailableDocs.length > 0) {
      return true;
    }
    return false;
  }

  getDynamicText(docType: string): string {
    console.log("getDynamicText document type - " + docType)
    switch (docType) {
      case 'OWNERS_MANUAL':
        return 'SEARCH.OWNERS_MANUAL_ICON_TEXT';
      case 'WARRANTY':
        return 'SEARCH.WARRANTY_ICON_TEXT';
      case 'TIRE WARRANTY':
        return 'SEARCH.TIRE_WARRANTY_ICON_TEXT';
      case 'COLLISION':
        return 'SEARCH.COLLISION_ICON_TEXT';
      case 'CA_EMISSIONS_PARTS':
        return 'SEARCH.CA_EMISSIONS_PARTS_ICON_TEXT';
      case 'CA EMISSIONS':
        return 'SEARCH.CA_EMISSIONS_ICON_TEXT';
      case 'ROADSIDE':
        return 'SEARCH.ROADSIDE_ICON_TEXT';
      case 'ORDER_LITERATURE':
        return 'SEARCH.ORDER_LITERATURE_ICON_TEXT';
      case 'BATTERY_CHARGING_CABLE_MANUAL':
        return 'SEARCH.BATTERY_CHARGING_CABLE_MANUAL_ICON_TEXT';
      default:
        return docType; // Fallback text if no specific type is matched
    }
  }

  getDynamicTextAudi(docType: string) {
    switch (docType) {
      case 'OWNERS_MANUAL':
        return 'SEARCH.OWNERS_MANUAL_ICON_TEXT';
      case 'WARRANTY':
        return 'SEARCH.WARRANTY_ICON_TEXT_AUDI';
      case 'TIRE WARRANTY':
        return 'SEARCH.TIRE_WARRANTY_ICON_TEXT';
      case 'COLLISION':
        return 'SEARCH.COLLISION_ICON_TEXT';
      case 'CA_EMISSIONS_PARTS':
        return 'SEARCH.CA_EMISSIONS_PARTS_ICON_TEXT';
      case 'CA EMISSIONS':
        return 'SEARCH.CA_EMISSIONS_ICON_TEXT_AUDI';
      case 'ROADSIDE':
        return 'SEARCH.ROADSIDE_ICON_TEXT';
      case 'ORDER_LITERATURE':
        return 'SEARCH.ORDER_LITERATURE_ICON_TEXT';
      case 'BATTERY_CHARGING_CABLE_MANUAL':
        return 'SEARCH.BATTERY_CHARGING_CABLE_MANUAL_ICON_TEXT';
      default:
        return docType; // Fallback text if no specific type is matched
    }
  }

  getIcon(docType: string): string {
    const iconMap = {
      'OWNERS_MANUAL': 'assets/images/ownersmanual.svg',
      'WARRANTY': 'assets/images/warrantymaintenancebooklet.svg',
      'TIRE WARRANTY': 'assets/images/tirewarrantyinfo.svg',
      'COLLISION': 'assets/images/collisionbrochure.svg',
      'CA_EMISSIONS_PARTS': 'assets/images/caemissionsparts.png',
      'CA EMISSIONS': 'assets/images/caemissionsparts.png',
      'ROADSIDE': 'assets/images/roadsidebrochure.svg',
      'ORDER_LITERATURE':'assets/images/orderliterature.svg',
      'BATTERY_CHARGING_CABLE_MANUAL':'assets/images/batterycharging.svg'
    };
    return iconMap[docType] || 'assets/images/defaulticon.png';
  }

  getIconForAudi(docType: string): string {
    const iconMap = {
      'OWNERS_MANUAL': 'assets/images/audi_owner_icon.svg',
      'WARRANTY': 'assets/images/audi_warrantybook_icon.svg',
      'TIRE WARRANTY': 'assets/images/audi_tire_warr_icon.svg',
      'COLLISION': 'assets/images/audi_collbroch_icon.svg',
      'CA_EMISSIONS_PARTS': 'assets/images/audi_caemiss_icon.svg',
      'CA EMISSIONS': 'assets/images/audi_caemiss_icon.svg',
      'ROADSIDE': 'assets/images/audi_roadsidebroch_icon.svg',
      'ORDER_LITERATURE':'assets/images/audi_order_ownlit_icon.svg',
      'BATTERY_CHARGING_CABLE_MANUAL':'assets/images/batterycharging.png'
    };
    return iconMap[docType] || 'assets/images/defaulticon.png';
  }

  // getDisabledIcon(docType: string): string {
  //   const disabledIconMap = {
  //     'OWNERS_MANUAL': 'assets/images/ownersmanual.png',
  //     'WARRANTY': 'assets/images/warrantymaintenancebooklet.png',
  //     'TIRE WARRANTY': 'assets/images/tirewarrantyinfo.png',
  //     'COLLISION': 'assets/images/collisionbrochure.png',
  //     'CA_EMISSIONS_PARTS': 'assets/images/caemissionsparts.png',
  //     'CA EMISSIONS': 'assets/images/caemissionswarranty.png',
  //     'ROADSIDE': 'assets/images/roadsidebrochure.png',
  //     'ORDER_LITERATURE':'assets/images/orderliterature.png'
  //   };
  //   return disabledIconMap[docType] || 'assets/images/defaulticon.png';
  // }

  anyUnavailableDocs(): boolean {
    return this.documentTypes.some(docType => !this.isDataAvailable(docType));
  }

  isLastUnavailable(docType: string): boolean {
   const docTypes = this.isCanadianMarket ? 
   (this.isElectricVehicle ? this.electricDocumentTypesCanada : this.documentTypesCanada) :
   (this.isElectricVehicle ? this.electricDocumentTypes : this.documentTypes);
    const unavailableDocs = docTypes.filter(type => !this.isDataAvailable(type));
    return unavailableDocs.indexOf(docType) === unavailableDocs.length - 1;
  }

  unavailableCount(docType: string): number {
    const docTypes = this.isCanadianMarket ? 
    (this.isElectricVehicle ? this.electricDocumentTypesCanada : this.documentTypesCanada) :
    (this.isElectricVehicle ? this.electricDocumentTypes : this.documentTypes);
    let unavailableDocs = [];
    unavailableDocs = docTypes.filter(type => !this.isDataAvailable(type));
     return unavailableDocs.length;
  }

  onContactUsClick() {
    console.log('Contact Us clicked!');
    window.open(this.customerCareUrl, '_blank');
  }

  processWhiteListDataResponse(whiteListDataResponse: any) {
    console.log(whiteListDataResponse);
    this.vehicleConfigService.setWhiteListData(whiteListDataResponse);
  }

  processVehicleConfigInfoResponse(vehicleInfoResponse: any, vehicleContext: VehicleContextObj) {
    if (!_.isNil(vehicleInfoResponse) && !(vehicleInfoResponse instanceof GetVehicleConfigError)) {
      this.vehicleContextForDisplay.model = vehicleInfoResponse.modelCode;
      this.vehicleContextForDisplay.model_year = vehicleInfoResponse.modelYear;
      this.vehicleContextForDisplay.carLine = vehicleInfoResponse.model;
      this.updateVehicleContextForDisplay(vehicleContext);
    }
  }

  displayVehicleResponse() {
    this.maintenanceResponse = this.vehicleConfigService.getMaintenanceResponse();
    this.literatureResponse = this.vehicleConfigService.getLiteratureResponse();

    // TODO: shiva here
    if (this.importerCode === CONST_IMPORTER_CODE_US) {
      if (!this.maintenanceResponse.isLoaded || !this.literatureResponse.isLoaded) {
        return;
      }

      if ((this.maintenanceResponse.isError && this.literatureResponse.isError) || (this.maintenanceResponse.isError) || (this.literatureResponse.isError)) {
        this.isCustomerCareLinkShown = true;
        this.errorMessage = 'SEARCH.NO_CONTENT_MESSAGE';
        this.verifyErrorCodeFromResponse(this.maintenanceResponse.data, this.literatureResponse.data);
      } else {
        this.isContentErrorShown = false;
        this.isContentLoaded = true;
        this.environmentService.removeBackgroundImage();
        this.spinnerVisible = false;
        this.scrollToOptionSelection();
      }
    } else {
      if (this.literatureResponse.isError) {
        this.isCustomerCareLinkShown = true;
        this.errorMessage = 'SEARCH.NO_OWNERS_LITERATURE_MESSAGE';
        this.verifyErrorCodeFromResponse(null, this.literatureResponse.data);
      } else {
        this.isContentErrorShown = false;
        this.isContentLoaded = true;
        this.environmentService.removeBackgroundImage();
        this.spinnerVisible = false;
        this.scrollToOptionSelection();
      }
    }
  }

  verifyErrorCodeFromResponse(maintenanceErrorResponse, literatureErrorResponse) {
    if (this.importerCode === CONST_IMPORTER_CODE_US) {
      if (!_.isNil(maintenanceErrorResponse) && !_.isNil(literatureErrorResponse)) {
        const maintenanceErrorCode = (!_.isNil(maintenanceErrorResponse.errors) && !_.isNil(maintenanceErrorResponse.errors[0])) ? maintenanceErrorResponse.errors[0].code : null;
        const ownersLiteratureErrorCode = (!_.isNil(literatureErrorResponse.errors) && !_.isNil(literatureErrorResponse.errors[0])) ? literatureErrorResponse.errors[0].code : null;

        if (maintenanceErrorCode === 6020 || ownersLiteratureErrorCode === 4101 || ownersLiteratureErrorCode === 4120) {
          this.isCustomerCareLinkShown = false;
          const outOfMarketTitle = 'SEARCH.OUT_OF_MARKET_MSG_USA';
          this.errorMessage = outOfMarketTitle;
          this.isFailedSubmission = true;
        } else if (maintenanceErrorCode === 6000 || ownersLiteratureErrorCode === 4100) {
          this.isCustomerCareLinkShown = false;
          this.errorMessage = 'SEARCH.INVALID_BRAND_MSG';
          this.isFailedSubmission = true;
        } else if (maintenanceErrorCode === 5000 || ownersLiteratureErrorCode === 5000) {
          this.errorMessage = 'SEARCH.INVALID_VEHICLE_MSG';
          this.isCustomerCareLinkShown = false;
          this.isFailedSubmission = true;
        }
        if (this.isFailedSubmission) {
          this.buildBaseEvent();
          this.event.model_code = "xyzxyz";
          this.event.model_year = 9999;
          this.event.model_name = 'NOVIN'
          this.event.vehicle_trim = 'NOVIN'
          this.cloudService.saveAuditDetails(this.event, 'Failed_VIN_Submissions');
        }
      }

    } else {
      if (!_.isNil(literatureErrorResponse)) {
        const ownersLiteratureErrorCode = (!_.isNil(literatureErrorResponse.errors) && !_.isNil(literatureErrorResponse.errors[0])) ? literatureErrorResponse.errors[0].code : null;

        if (ownersLiteratureErrorCode === 4101 || ownersLiteratureErrorCode === 4120) {
          this.isCustomerCareLinkShown = false;
          const outOfMarketTitle = 'SEARCH.OUT_OF_MARKET_MSG_CA';
          this.isFailedSubmission = true;
          this.errorMessage = outOfMarketTitle;
        } else if (ownersLiteratureErrorCode === 4100) {
          this.isCustomerCareLinkShown = false;
          this.errorMessage = 'SEARCH.INVALID_BRAND_MSG';
          this.isFailedSubmission = true;
        } else if (ownersLiteratureErrorCode === 5000) {
          this.errorMessage = 'SEARCH.INVALID_VEHICLE_MSG';
          this.isFailedSubmission = true;
          this.isCustomerCareLinkShown = false;
        }
        if (this.isFailedSubmission) {
          this.buildBaseEvent();
          this.event.model_code = "xyzxyz";
          this.event.model_year = 9999;
          this.event.model_name = 'NOVIN'
          this.event.vehicle_trim = 'NOVIN'
          this.cloudService.saveAuditDetails(this.event, 'Failed_VIN_Submissions');
        }
      }
    }
    this.isContentErrorShown = true;
    this.isContentLoaded = false;
    this.environmentService.updateBackgroundImage();
    this.spinnerVisible = false;
    this.calculateScreenHeight();
  }

  get vin() {
    return this.form.get('vin');
  }

  get odometer() {
    return this.form.get('odometer');
  }

  get modelYear() {
    return this.form.get('modelYear');
  }

  get carLine() {
    return this.form.get('carLine');
  }

  get model_trim() {
    return this.form.get('model_trim');
  }

  editVIN() {
    if (!this.vin.value) {
      return;
    }
    const value = this.vin.value;
    this.vin.setValue(value.toUpperCase());
    this.resetDropdownValues();
  }

  clearSelectionValues() {
    this.isContentErrorShown = false;
    this.isContentLoaded = false;
    this.maintenanceResponse = null;
    this.isElectricVehicle = false;
    this.isLiteratureDisclaimerShown = true;
    // this.cloudService.clearVehicleContext(); TO DO: Add to clear vehicle context in vehicle config service
    this.cloudService.setIsVehicleInContext(false);
    this.vehicleConfigService.setVehicleInContext(false);
    this.vehicleConfigService.setVehicleElectric(false);
    this.environmentService.updateBackgroundImage();
    this.vehicleConfigService.setDefaultResponseType(this.importerCode);
  }

  resetDropdownValues(): void {
    this.form.patchValue({ modelYear: '' });
    this.form.patchValue({ model_trim: '' });
    this.form.patchValue({ carLine: '' });
    this.carLines = [];
    this.modelTrims = [];

    this.selectedModelYear = null;
    this.displayModelYear = null;
    this.selectedModelTrim = '';
    this.displayCarLine = '';
    this.selectedCarLine = '';
  }

  fetchModelYears(): void {
    this.cloudService.fetchModelYears().pipe(takeUntil(this.searchPageDestroyed$)).subscribe((modelYears: ModelYear[]) => {
      this.modelYears = modelYears;
      this.cloudService.setVehicleContextModelYears(modelYears);
    }, error => {
      this.getModelYearErrorOccurred = true;
      this.cloudService.showPopup('An error occurred while fetching the model years');
    });
  }

  fetchCarLines(year: number) {
    this.cloudService.fetchCarLines(year).pipe(takeWhile(() => this.alive)).subscribe((carLines: CarLine[]) => {
      this.carLines = carLines;
    }, error => {
      this.resetDropdownValues();
      this.cloudService.showPopup('An error occurred while fetching the car lines');
    });
  }

  fetchModelTrims(year: number, carLine: string) {
    this.cloudService.fetchModelTrims(year, carLine).pipe(takeWhile(() => this.alive)).subscribe((modelTrims: ModelTrim[]) => {
      this.modelTrims = modelTrims;
    }, error => {
      this.form.patchValue({ model_trim: '' });
      this.selectedModelTrim = '';
      this.displayCarLine = '';
      this.modelTrims = [];
      this.cloudService.showPopup('An error occurred while fetching the model trims');
    });
  }

  onSelectModelYear(modelYear: number) {
    this.vin.setValue('');
    if (this.vin.invalid) {
      this.vin.markAsUntouched();
    }
    if (modelYear) {
      this.selectedModelYear = modelYear;
      this.fetchCarLines(modelYear);
    } else {
      this.selectedModelYear = null;
      this.form.patchValue({ modelYear: '' });
    }
    this.carLines = [];
    this.modelTrims = [];
    this.selectedModelTrim = '';
    this.selectedCarLine = '';
    this.form.patchValue({ model_trim: '' });
    this.form.patchValue({ carLine: '' });
    this.clearContentArea();
  }

  onSelectCarLine(carLine: string) {
    this.vin.setValue('');
    // remove error message, if any
    if (this.vin.invalid) {
      this.vin.markAsUntouched();
    }
    this.modelTrims = [];
    this.form.patchValue({ model_trim: '' });
    this.selectedModelTrim = '';
    if (carLine && this.selectedModelYear) {
      this.selectedCarLine = carLine;
      this.fetchModelTrims(this.selectedModelYear, carLine);
    }
    if (!carLine) {
      this.selectedCarLine = '';
    }
    this.clearContentArea();
  }

  onSelectModelTrim(modelTrim: string) {
    this.vin.setValue('');
    // remove error message, if any
    if (this.vin.invalid) {
      this.vin.markAsUntouched();
    }
    if (modelTrim) {
      this.selectedModelTrim = modelTrim;
      let selectedModelTrimObj = this.modelTrims.find((trim) => trim.salesModelCode == modelTrim);
      if (!_.isNil(selectedModelTrimObj)) {
        this.selectedModelTrimDesc = selectedModelTrimObj.modelTrimDescription;
      }
      console.log(this.selectedModelTrimDesc);
    } else {
      this.selectedModelTrim = '';
    }
    this.clearContentArea();
  }

  // User can only input numbers
  onlyNumberMileage(event) {
    if (event.keyCode === 13) {
      if (this.searchButtonEnabled || !this.vin.invalid) {
        this.searchForPackages();
      }
      return false;
    }
  }

  // add commas to the inputted mileage
  addCommas(event?) {
    const target = event.target;
    let input = target.value;
    input = input.replace(/\,/g, '');
    // input = !isNaN(input) ? Number(input).toLocaleString(): '';
    if (isNaN(input)) {
      const length = input.length;
      input = input.substring(0, length - 1);
    }
    target.value = Number(input).toLocaleString();
    if (input === '') {
      target.value = '';
    }
  }

  onVinPaste(event) {
    if (event.target.value === this.vin.value) {
      this.clearContentArea();
    }
  }

  clearContentArea() {
    // remove error message, if any
    if (this.vin.invalid) {
      this.vin.markAsUntouched();
    }
    this.isContentErrorShown = false;
    this.isContentLoaded = false;
    this.maintenanceResponse = null;
    this.isLiteratureDisclaimerShown = true;

    this.environmentService.updateBackgroundImage();
    this.isElectricVehicle = false;
    this.vehicleConfigService.setVehicleElectric(false);

    this.modelYears = this.cloudService.getVehicleContextModelYears();
    this.isFixedFooter = true;
  }

  // disable the search button until a valid selection is made
  get searchButtonEnabled(): boolean {
    return (this.selectedCarLine && this.selectedCarLine.length > 0) &&
      (this.selectedModelTrim && this.selectedModelTrim.length > 0) &&
      (this.selectedModelYear !== null);
  }

  isSearchButtonEnabled() {
    return (this.selectedCarLine && this.selectedCarLine.length > 0) &&
      (this.selectedModelTrim && this.selectedModelTrim.length > 0) &&
      (this.selectedModelYear !== null);
  }

  scrollToOptionSelection() {
    // const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;
    // const scrollDiv = document.querySelector('body') as HTMLBodyElement;
    // const SearchSelectionElement = document.getElementById('searchSelection');
    // const scrollSelectionTop = (document.querySelector('.selectScroll') as HTMLElement).offsetTop;
    setTimeout(function () { document.getElementById('searchSelection').scrollIntoView(); }, 1);
  }

  scrollerToTop() {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    setTimeout(function () {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.scrollTo(0, 0);
      }
    }, 10);
  }

  calculateScreenHeight() {
    setTimeout(() => {
      this.isFixedFooter = !(document.body.scrollHeight > (this.getViewportSize().h - 50));
    });
  }

  getViewportSize(w?) {
    w = w || window;
    // Works for all browsers except IE8 and before
    if (w.innerWidth != null) {
      return { w: w.innerWidth, h: w.innerHeight };
    }

    // For IE (or any browser) in Standards mode
    var d = w.document;
    if (document.compatMode == 'CSS1Compat') {
      return {
        w: d.documentElement.clientWidth,
        h: d.documentElement.clientHeight
      };
    }
    // For browsers in Quirks mode
    return { w: d.body.clientWidth, h: d.body.clientHeight };
  }

  openUrl(docType: string): void {
    const literature = this.getLiterature(docType);
    if (literature && literature.url) {
      window.open(literature.url, '_blank');
    } else {
      console.log('No URL available for:', docType);
    }
  }

  onLinkSelect(type: string) {
    console.log('Control Comes Here' + type);
    if (type === 'MAINTENANCE') {
      this.vehicleConfigService.setVehicleInContext(true);
      this.cloudService.setVehicleContextModelYears(this.modelYears);
      this.cloudService.setVehicleContextCarLines(this.carLines);
      this.cloudService.setVehicleContextModelTrims(this.modelTrims);
      this.guard.allow = true;
      this.router.navigateByUrl('packages', { skipLocationChange: true });
      this.cloudService.saveAuditDetails(this.event, 'Click_Maintenance_Schedule');
      this.cloudService.saveAuditDetails(this.event, 'Maintenance_Page_Click');
      this.cloudService.saveAuditDetails(this.event, 'Page_Click');

    }
    if (type === 'OWNERS_MANUAL') {
      this.cloudService.saveAuditDetails(this.event, 'Click_Owners_Manual');
      this.cloudService.saveAuditDetails(this.event, 'Page_Click');
    }
    if (type === 'WARRANTY') {
      this.cloudService.saveAuditDetails(this.event, 'Click_Warranty_Book');
      this.cloudService.saveAuditDetails(this.event, 'Page_Click');
    }
    if (type === 'TIRE WARRANTY') {
      this.cloudService.saveAuditDetails(this.event, 'Click_Tire_Warranty');
      this.cloudService.saveAuditDetails(this.event, 'Page_Click');
    }
    if (type === 'CA EMISSIONS') {
      this.cloudService.saveAuditDetails(this.event, 'Click_CA_Emissions');
      this.cloudService.saveAuditDetails(this.event, 'Page_Click');
    }
    if (type === 'CA_EMISSIONS_PARTS') {
      this.cloudService.saveAuditDetails(this.event, 'Click_CA_Emissions_Parts');
      this.cloudService.saveAuditDetails(this.event, 'Page_Click');
    }
    if (type === 'ORDER_LITERATURE') {
      this.cloudService.saveAuditDetails(this.event, 'Click_Order_Owners_Lit');
      this.cloudService.saveAuditDetails(this.event, 'Page_Click');
    }
    if (type === 'ROADSIDE') {
      this.cloudService.saveAuditDetails(this.event, 'Click_Roadside');
      this.cloudService.saveAuditDetails(this.event, 'Page_Click');
    }
    if (type === 'COLLISION') {
      this.cloudService.saveAuditDetails(this.event, 'Click_Collision');
      this.cloudService.saveAuditDetails(this.event, 'Page_Click');
    }
  }

  onContactUsLinkSelect(type: string) {
    if (type === 'MAINTENANCE') {
      this.cloudService.saveAuditDetails(this.event, 'Click_Contact_Us_in_Maintenance');
    } else {
      this.cloudService.saveAuditDetails(this.event, 'Click_Contact_Us_in_Owners_Literature');
    }
  }
  
  onContactUsFooterSelect(type: string){
    if(!_.isNil(this.event.vin) || !_.isNil(this.event.vehicle_trim)){
     //VINorVehicleBuildIsSelected 
    }else{
      //this.buildBaseEvent();
      this.event.entry_point_url = location.host;
      this.event.source = this.browser;
      this.event.source_type = this.device;
      this.event.user_brand = this.brand;
      this.event.user_brand = this.brand;
      this.event.user_importer_code = this.importerCode;
      this.event.vehicle_brand = this.brand;
      this.event.vehicle_importer_code = this.importerCode;
      if (this.importerCode == '444') {
        this.event.language_code = this.userSelectedLanguage + "-US";
      } else {
        this.event.language_code = type + "-CA";
      }
      this.event.model_code = "ABCDEF";
      this.event.model_year = 9999;
      this.event.model_name = 'NA';
      this.event.vehicle_trim = 'NA';
      this.event.vin='NA';
      //console.log(">>>>>>>>>>>>>>>>>>>>"+this.event.vin);
    }
    if (type === 'PRIVACY_POLICY') {
      this.cloudService.saveAuditDetails(this.event, 'Click_Privacy_Policy_in_Footer');
    } else {
      this.cloudService.saveAuditDetails(this.event, 'Click_Contact_Us_in_Footer');
    }
  }

  clearSelection() {
    this.form.patchValue({ modelYear: '' });
    this.form.patchValue({ model_trim: '' });
    this.form.patchValue({ carLine: '' });
    this.form.patchValue({ vin: '' });

    this.selectedModelYear = null;
    this.displayModelYear = null;
    this.selectedModelTrim = '';
    this.displayCarLine = '';
    this.selectedCarLine = '';
    this.odometerValue = 0;

    this.carLines = [];
    this.modelTrims = [];

    this.vin.setValue('');
    this.odometer.setValue('');

    // remove error message, if any
    if (this.vin.invalid) {
      this.vin.markAsUntouched();
    }

    this.isContentErrorShown = false;
    this.isContentLoaded = false;
    this.maintenanceResponse = null;
    this.isLiteratureDisclaimerShown = true;

    this.environmentService.updateBackgroundImage();
    this.isElectricVehicle = false;
    this.vehicleConfigService.setVehicleElectric(false);

    // this.cloudService.clearVehicleContext(); //TO DO clearing vehicle context
    this.modelYears = this.cloudService.getVehicleContextModelYears();
    this.isFixedFooter = true;
    this.clearEventVariables();
  }

  goBack(event?: boolean) {
    if (this.isMaintenancePackageShown) {
      const vinValue = this.vin.value.trim().toUpperCase();
      this.odometer.setValue(this.odometerValue.toLocaleString());
      this.vin.setValue(vinValue);
      this.isMaintenancePackageShown = false;
      this.isSelectionOptionShown = false;
    }
  }

  isVINLengthExceeded() {
    return this.vin.value && this.vin.value.length > 17;
  }

  // search for packages if 'Enter' key is pressed in VIN input
  onVinKeyEnter(event) {
    if (!_.isNil(event) && !_.isNil(event.target) && !_.isNil(event.target.value) && event.target.value.length > 0) {
      if ((event.keyCode === 13)) {
        if (this.searchButtonEnabled || !this.vin.invalid) {
          this.searchForPackages();
        }
        const value = event.target.value;
        event.target.value = "";
        // event.target.value = value.trim().toUpperCase();
        this.vin.setValue("");
        this.vin.setValue(value.toUpperCase());
        event.preventDefault();
      }
      if (event.key === 'Delete' || event.key === 'Backspace' || event.key === 'x') {
        this.clearContentArea();
      }
      this.resetDropdownValues();
    }
  }

  onlyNumberKey(event) {
    const target = event.target;
    let input = target.value;
    if (input) {
      input = input.trim();
    }
    if ((this.vin.value.length >= 11) && isNaN(input)) {
      const length = input.length;
      input = input.substring(0, length - 1);
    }
    if (event.keyCode === 13) {
      if (this.searchButtonEnabled || !this.vin.invalid) {
        this.searchForPackages();
      }
      return false;
    }
  }

  onMileagePaste(event: ClipboardEvent) {
    if (event && event.clipboardData) {
      const pasteText: string = event.clipboardData.getData('text/plain') || '';
      if (pasteText && pasteText.trim().length > 6) {
        event.preventDefault();
      }
    }
  }

  isAndroid() {
    const userAgent = navigator.userAgent || navigator.vendor;
    return /android/i.test(userAgent);
  }

  specialCharacterFoundInVIN(vin: string): boolean {
    const regex = /^[a-hj-npr-z0-9]*$/i;
    const value = vin ? vin.toLowerCase() : '';
    const length = value.length;
    let index = 0;
    while (index < length) {
      if (!regex.test(value[index++])) {
        return true;
      }
    }
    return false;
  }

  ngAfterViewInit() {
    if (this.isContentLoaded) {
      this.calculateScreenHeight();
    }
  }

  ngOnDestroy() {
    // Garbage collect all subscriptions
    this.searchPageDestroyed$.next(true);
    this.searchPageDestroyed$.complete();
    this.searchPageDestroyed$.unsubscribe();
  }
}
