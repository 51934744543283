import { TimeBasedService } from './../models/time-based-service.model';
import { VehicleDetailsResponseObj, MaintenanceIntervals } from './../models/maintenance-package.model';
import { VehicleContextForPrint, VehicleContextObj } from './../models/vehicle-context.model';
import { CONST_CA_EMISSIONS_DOCTYPE, CONST_TIRE_WARRANTY_DOCTYPE, CONST_WARRANTY_DOCTYPE, URL_DOWNLOAD_OWNERS_LITERATURE_DOCS, URL_OWNERS_MANUAL_VW, URL_OWNERS_MANUAL_AUDI, URL_ORDER_OWNERS_LITERATURE_VW, URL_ORDER_OWNERS_LITERATURE_AUDI, CONST_OWNERS_MANUAL_DOCTYPE, CONST_ORDER_LITERATURE_DOCTYPE, CONST_CA_EMISSIONS_PARTS_DOCTYPE, CONST_IMPORTER_CODE_US, URL_CA_EMISSION_PART_LIST_VW, CONST_BRAND_VW, URL_CA_EMISSION_PART_LIST_AUDI, CONST_MAINTENANCE_RESPONSE_TYPE, CONST_OWNERS_LITERATURE_RESPONSE_TYPE, CONST_MAINTENANCE_CARD_DOCTYPE, URL_CANADA_OWNERS_MANUAL_VW, URL_CANADA_OWNERS_MANUAL_AUDI, CONST_ABRIDGE_OWNER_DOCTYPE, CONST_BATTERY_CHARGING_CABLE_MANUAL, CONST_COLLISION_BROCHURE_DOCTYPE, CONST_ROADSIDE_BROCHURE_DOCTYPE, URL_CANADA_OWNERS_MANUAL_AUDI_EN, URL_CANADA_OWNERS_MANUAL_AUDI_FR, URL_US_OWNERS_MANUAL_AUDI } from './../app.constants';
import { OwnersLiteratureArray, OwnersLiteratureResponse } from './../models/owners-literature-documents.model';
import { CloudService } from '../services/cloud.service';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { EnvironmentService } from './environment.service';
import { HttpClient } from '@angular/common/http';
import { EventReport } from '../models/event-report-model';

@Injectable({
  providedIn: 'root'
})
export class VehicleConfigLookupService {

  private isVehicleInContext: boolean = false;

  private ownersLiteratureArray: OwnersLiteratureArray[];

  private isElectricVehicle: boolean = false;

  private vehicleContext: VehicleContextObj = null;

  private vehicleDetailsResponse: VehicleDetailsResponseObj[];

  private timeBasedServices: TimeBasedService[];

  private manipulatedMaintenanceIntervals: MaintenanceIntervals[];

  private vehicleContextForPrint: VehicleContextForPrint;

  private maintenanceCardUrl: string = '';

  private eventReport: EventReport;

  private whiteListData: any;
  private submitResponse: any;
  docTypeResp: string;
  packageId: string;

  

  private ownersLitratureResponseForAbridge : OwnersLiteratureResponse;



  constructor(private http: HttpClient, private cloudService: CloudService, private environmentService: EnvironmentService) {
  }

  getEventReport(): EventReport {
    return this.eventReport;
  }

  setEventReport(eventReport: EventReport) {
    this.eventReport = eventReport;
  }

  setWhiteListData(whiteListData: any): void {
    this.whiteListData = whiteListData;
  };

  setOwnersLitratureResponseForAbridge(ownersLitratureResponseForAbridge: OwnersLiteratureResponse): void {
    this.ownersLitratureResponseForAbridge = ownersLitratureResponseForAbridge;
  };

  setLiteratureResponse(literatureResponse: any): void {
    this.submitResponse = literatureResponse;
  };


  isVehicleDetailInContext() {
    return this.isVehicleInContext;
  }

  setVehicleInContext(isVehicleInContext: boolean) {
    this.isVehicleInContext = isVehicleInContext;
  }

  isVehicleElectric(): boolean {
    return this.isElectricVehicle;
  }

  setVehicleElectric(isElectricVehicle: boolean) {
    this.isElectricVehicle = isElectricVehicle;
  }

  setVehicleDetailsResponse(vehicleDetailsResponse) {
    this.vehicleDetailsResponse = vehicleDetailsResponse;
  }

  getVehicleDetailsResponse(): VehicleDetailsResponseObj[] {
    return this.vehicleDetailsResponse;
  }

  setVehicleContext(vehicleContext: VehicleContextObj) {
    this.vehicleContext = vehicleContext;
  }

  getVehicleContext(): VehicleContextObj {
    return this.vehicleContext;
  }

  getVehicleContextForPrint(): VehicleContextForPrint {
    return this.vehicleContextForPrint;
  }

  setVehicleContextForPrint(vehicleContextForPrint: VehicleContextForPrint) {
    this.vehicleContextForPrint = vehicleContextForPrint;
  }

  getAllMaintenancePackages() {
    const maintenancePackages = this.vehicleDetailsResponse.find(response => response.responseType === CONST_MAINTENANCE_RESPONSE_TYPE).data;
    return ((!_.isNil(maintenancePackages) && maintenancePackages.length > 0) ? maintenancePackages : []);
  }

  getPackageId() {
   return this.packageId;
  }

  setPackageId(packageId: string) {
    this.packageId = packageId;
  }

  getManipulatedMaintenanceIntervals(): MaintenanceIntervals[] {
    return this.manipulatedMaintenanceIntervals;
  }


  setManipulatedMaintenanceIntervals(maintenaceIntervals: MaintenanceIntervals[]) {
    this.manipulatedMaintenanceIntervals = maintenaceIntervals;
  }

  getTimeBasedServices(): TimeBasedService[] {
    return this.timeBasedServices;
  }

  setTimeBasedServices(timeBasedServices: TimeBasedService[]) {
    const sortedTimeBasedServices = this.sortTimeBasedServices(timeBasedServices);
    this.timeBasedServices = (!_.isNil(sortedTimeBasedServices)) ? sortedTimeBasedServices : [];
  }

  sortTimeBasedServices(timeBasedServices: TimeBasedService[]): TimeBasedService[] {
    if (!_.isNil(timeBasedServices) && timeBasedServices.length > 0) {
      timeBasedServices = timeBasedServices.sort((item1, item2) => {
        const firstItem = (!_.isNil(item1) && !_.isNil(item1.description) && !_.isNil(item1.description)) ? item1.description.toLowerCase().trim() : '';
        const secondItem = (!_.isNil(item2) && !_.isNil(item2.description) && !_.isNil(item2.description)) ? item2.description.toLowerCase().trim() : '';
        return (firstItem < secondItem) ? -1 : (firstItem > secondItem) ? 1 : 0;
      });
    }
    return timeBasedServices;
  }

  resetVariables(brand, userImporterCode) {
    this.clearVariables();
    this.setDefaultOwnersLiteratureArray(brand, userImporterCode);
    this.setDefaultResponseType(userImporterCode);
  }

  updateVehicleDetailsResponse(response, responseType: string, isLoaded: boolean, isError: boolean, forReport: boolean) {
    const vehicleResponse = this.vehicleDetailsResponse.find(response => response.responseType === responseType);
    vehicleResponse.data = response;
    vehicleResponse.isLoaded = isLoaded;
    vehicleResponse.isError = isError; //lITERATURE response CANNOT BE ERRORED SINCE WE HAVE ATLEAST ONE LINK TO DISPLAY ALWAYS
    if (responseType === CONST_MAINTENANCE_RESPONSE_TYPE) {
      vehicleResponse.isDataAvailable = (response.length > 0) ? true : false;
      //if(!vehicleResponse.isDataAvailable){this.cloudService.saveAuditDetails(this.getEventReport(), "No_Maintenance_Error");}
    } else {
      vehicleResponse.isDataAvailable = this.getDataAvailabilityOfLiteratureResponse(vehicleResponse, forReport);
    }
    console.log(vehicleResponse);
  }

  getMaintenanceResponse() {
    return this.vehicleDetailsResponse.find(response => response.responseType === CONST_MAINTENANCE_RESPONSE_TYPE);
  }

  getLiteratureResponse() {
    return this.vehicleDetailsResponse.find(response => response.responseType === CONST_OWNERS_LITERATURE_RESPONSE_TYPE);
  }

  getDataAvailabilityOfLiteratureResponse(literatureResponse: VehicleDetailsResponseObj, forReport: boolean): boolean {
    let isDataAvailable = true;
    console.log(literatureResponse.data);
    if (!_.isNil(literatureResponse) && !_.isNil(literatureResponse.data)) {
      if (literatureResponse.isError) {

      } else {
        const literatureResponseCount = literatureResponse.data.length;
        let dataAvailabilityCount = 0;
        literatureResponse.data.forEach(response => {
          dataAvailabilityCount = (response['isDataAvailable']) ? (dataAvailabilityCount + 1) : dataAvailabilityCount;
          if(forReport){
              if((response['docType']=='WARRANTY') && !response['isDataAvailable']){
                this.cloudService.saveAuditDetails(this.getEventReport(), 'No_Warranty_error');
              }
              if((response['docType']=='TIRE WARRANTY') && !response['isDataAvailable']){
                this.cloudService.saveAuditDetails(this.getEventReport(), 'No_Tire_Warranty_error');
              }
              if((response['docType']=='CA EMISSIONS') && !response['isDataAvailable']){
                this.cloudService.saveAuditDetails(this.getEventReport(), 'No_CA_Emissions_error');
              }
              if((response['docType']=='ROADSIDE') && !response['isDataAvailable']){
                this.cloudService.saveAuditDetails(this.getEventReport(), 'No_Roadside_error');
              }
              if((response['docType']=='COLLISION') && !response['isDataAvailable']){
                this.cloudService.saveAuditDetails(this.getEventReport(), 'No_Collision_error');
              }
          }
        });
        isDataAvailable = (dataAvailabilityCount !== 0) ? true : false;
      }
    }
    return isDataAvailable;
  }

  setDefaultResponseType(userImporterCode: string) {
    if (userImporterCode === CONST_IMPORTER_CODE_US) {
      this.vehicleDetailsResponse = [
        {
          responseType: CONST_MAINTENANCE_RESPONSE_TYPE,
          data: [],
          isError: false,
          isLoaded: false,
          isDataAvailable: false
        },
        {
          responseType: CONST_OWNERS_LITERATURE_RESPONSE_TYPE,
          data: [],
          isError: false,
          isLoaded: false,
          isDataAvailable: true
        }
      ];
    } else {
      this.vehicleDetailsResponse = [
        {
          responseType: CONST_MAINTENANCE_RESPONSE_TYPE,
          data: [],
          isError: false,
          isLoaded: false,
          isDataAvailable: false
        },
        {
          responseType: CONST_OWNERS_LITERATURE_RESPONSE_TYPE,
          data: [],
          isError: false,
          isLoaded: false,
          isDataAvailable: true
        }
      ];
    }
  }

  setDefaultOwnersLiteratureArray(brand: string, userImporterCode: string): void {
    
    if (userImporterCode === CONST_IMPORTER_CODE_US) {
      this.ownersLiteratureArray = [
        {
          docType: CONST_OWNERS_MANUAL_DOCTYPE,
          isDataAvailable: false,
          data: {},
          url: '',
          headerMessage: 'SEARCH.OWNERS_MANUAL_LINK_TEXT',
          errorMessage: 'SEARCH.NO_OWNERS_MANUAL_MESSAGE',
          isLoaded: false,
          isError: true
        },
        {
          docType: CONST_BATTERY_CHARGING_CABLE_MANUAL,
          isDataAvailable: false,
          data: {},
          url: '',
          headerMessage: 'SEARCH.BATTERY_CHARGING_CABLE_MANUAL_LINK_TEXT',
          errorMessage: 'SEARCH.NO_BATTERY_CHARGING_CABLE_MANUAL_MESSAGE',
          isLoaded: false,
          isError: true
        },
        /*{
          docType: CONST_ABRIDGE_OWNER_DOCTYPE,
          isDataAvailable: false,
          data: {},
          url: '',
          headerMessage: 'SEARCH.ABRIDGED_OWNERS_MANUAL_LINK_TEXT',
          errorMessage: '',
          isLoaded: true,
          isError: true
        },*/
        {
          docType: CONST_WARRANTY_DOCTYPE,
          isDataAvailable: false,
          data: {},
          url: '',
          headerMessage: ('SEARCH.' + ((brand == CONST_BRAND_VW) ? 'WARRANTY_LINK_TEXT' : 'WARRANTY_LINK_TEXT_AUDI')),
          errorMessage: 'SEARCH.NO_WARRANTY_BOOKLET_MESSAGE',
          isLoaded: false,
          isError: true
        },
        {
          docType: CONST_TIRE_WARRANTY_DOCTYPE,
          isDataAvailable: false,
          data: {},
          url: '',
          headerMessage: ('SEARCH.' + ((brand == CONST_BRAND_VW) ? 'TIRE_WARRANTY_LINK_TEXT' : 'TIRE_WARRANTY_LINK_TEXT_AUDI')),
          errorMessage: 'SEARCH.NO_TIRE_WARRANTY_MESSAGE',
          isLoaded: false,
          isError: true
        },
        {
          docType: CONST_CA_EMISSIONS_DOCTYPE,
          isDataAvailable: false,
          data: {},
          url: '',
          headerMessage: ('SEARCH.' + ((brand == CONST_BRAND_VW) ? 'EMISSIONS_LINK_TEXT_US' : 'EMISSIONS_LINK_TEXT_AUDI_US')),
          errorMessage: 'SEARCH.NO_CA_EMISSIONS_MESSAGE',
          isLoaded: false,
          isError: true
        },
        {
          docType: CONST_CA_EMISSIONS_PARTS_DOCTYPE,
          isDataAvailable: true,
          data: {},
          url: ((brand == CONST_BRAND_VW) ? URL_CA_EMISSION_PART_LIST_VW : URL_CA_EMISSION_PART_LIST_AUDI),
          headerMessage: 'SEARCH.EMISSIONS_PARTS_LIST_LINK_TEXT',
          errorMessage: '',
          isLoaded: false,
          isError: true
        },
        {
          docType: CONST_COLLISION_BROCHURE_DOCTYPE,
          isDataAvailable: false,
          data: {},
          url:'',
          headerMessage: ('SEARCH.' + ((brand == CONST_BRAND_VW) ? 'COLLISION_BROCHURE_VW' : 'COLLISION_BROCHURE')),
          errorMessage: 'SEARCH.NO_COLLISION_BROCHURE',
          isLoaded: false,
          isError: true
        },
        {
          docType: CONST_ROADSIDE_BROCHURE_DOCTYPE,
          isDataAvailable: false,
          data: {},
          url: '',
          headerMessage: ('SEARCH.' + ((brand == CONST_BRAND_VW) ? 'ROADSIDE_BROCHURE_VW' : 'ROADSIDE_BROCHURE')),
          errorMessage: 'SEARCH.NO_ROADSIDE_BROCHURE',
          isLoaded: false,
          isError: true
        },
        {
          docType: CONST_ORDER_LITERATURE_DOCTYPE,
          isDataAvailable: true,
          data: {},
          url: ((brand == 'V') ? URL_ORDER_OWNERS_LITERATURE_VW : URL_ORDER_OWNERS_LITERATURE_AUDI),
          headerMessage: 'SEARCH.ORDER_OWNERS_LITERATURE_LINK_TXT',
          errorMessage: '',
          isLoaded: true,
          isError: false
        }];
    } else {
      this.ownersLiteratureArray = [
        {
          docType: CONST_OWNERS_MANUAL_DOCTYPE,
          isDataAvailable: false,
          data: {},
          url: '',
          headerMessage: 'SEARCH.OWNERS_MANUAL_LINK_TEXT',
          errorMessage: 'SEARCH.NO_OWNERS_MANUAL_MESSAGE',
          isLoaded: false,
          isError: true
        },
        /*{
          docType: CONST_ABRIDGE_OWNER_DOCTYPE,
          isDataAvailable: false,
          data: {},
          url: '',
          headerMessage: 'SEARCH.ABRIDGED_OWNERS_MANUAL_LINK_TEXT',
          errorMessage: '',
          isLoaded: true,
          isError: true
        },*/
        {
          docType: CONST_WARRANTY_DOCTYPE,
          isDataAvailable: false, data: {},
          url: '',
          headerMessage: ('SEARCH.' + ((brand == CONST_BRAND_VW) ? 'WARRANTY_LINK_TEXT' : 'WARRANTY_LINK_TEXT_AUDI')),
          errorMessage: 'SEARCH.NO_WARRANTY_BOOKLET_MESSAGE',
          isLoaded: false,
          isError: true
        },
        {
          docType: CONST_TIRE_WARRANTY_DOCTYPE,
          isDataAvailable: false,
          data: {},
          url: '',
          headerMessage: ('SEARCH.' + ((brand == CONST_BRAND_VW) ? 'TIRE_WARRANTY_LINK_TEXT' : 'TIRE_WARRANTY_LINK_TEXT_AUDI')),
          errorMessage: 'SEARCH.NO_TIRE_WARRANTY_MESSAGE',
          isLoaded: false,
          isError: true
        },
        {
          docType: CONST_COLLISION_BROCHURE_DOCTYPE,
          isDataAvailable: false,
          data: {},
          url:'',
          headerMessage: ('SEARCH.' + ((brand == CONST_BRAND_VW) ? 'COLLISION_BROCHURE_VW' : 'COLLISION_BROCHURE')),
          errorMessage: 'SEARCH.NO_COLLISION_BROCHURE',
          isLoaded: false,
          isError: true
        },
        {
          docType: CONST_ORDER_LITERATURE_DOCTYPE,
          isDataAvailable: true,
          data: {},
          url: ((brand == 'V') ? URL_ORDER_OWNERS_LITERATURE_VW : URL_ORDER_OWNERS_LITERATURE_AUDI),
          headerMessage: 'SEARCH.ORDER_OWNERS_LITERATURE_LINK_TXT',
          errorMessage: '',
          isLoaded: true,
          isError: false
        }];
    }
  }

  getUpdateWhiteData(data) {
    return data;
  }

  getOwnersLiteratureArray(): OwnersLiteratureArray[] {
    return this.ownersLiteratureArray;
  }

  getMaintenanceCardUrl(): string {
    return this.maintenanceCardUrl;
  }

  updateOwnersLiteratureArray(literatureResponse: OwnersLiteratureResponse, modelYear: number, modelCode: string, vin: string, userImporterCode: string, language: string): void {
    this.maintenanceCardUrl = '';
    if (!_.isNil(literatureResponse) && !_.isNil(literatureResponse.ownersLiteratureDocuments) && literatureResponse.ownersLiteratureDocuments.length > 0) {
      literatureResponse.ownersLiteratureDocuments.forEach((document) => {
        //if (!_.isNil(document.document_type) && (document.document_type !== CONST_MAINTENANCE_CARD_DOCTYPE && document.document_type !== CONST_ABRIDGE_OWNER_DOCTYPE)){
          if (!_.isNil(document.document_type) && (document.document_type !== CONST_MAINTENANCE_CARD_DOCTYPE)){
          const literatureDocument = this.ownersLiteratureArray.find(literature => literature.docType === document.document_type.toUpperCase());
          if(literatureDocument) {
            literatureDocument.url = this.environmentService.getOwnersLiteratureOrigin() + URL_DOWNLOAD_OWNERS_LITERATURE_DOCS + '/' + document.id;
            literatureDocument.isDataAvailable = true;
            literatureDocument.isLoaded = true;
            literatureDocument.isError = false;
          }

        } else {
          this.maintenanceCardUrl = this.environmentService.getOwnersLiteratureOrigin() + URL_DOWNLOAD_OWNERS_LITERATURE_DOCS + '/' + document.id;
        }
      });
    }
    const brand = this.environmentService.getBrand();
    // this.updateOrderLiteratureLink(brand);
    //if (userImporterCode === CONST_IMPORTER_CODE_US) {
      this.updateOwnersManualLink(brand, modelYear, vin, userImporterCode, language);
    if (userImporterCode === CONST_IMPORTER_CODE_US) {
      this.updateEmissionPartsLinks(modelYear, modelCode);
    }
  }

  /*isWhiteListModelUpdateErrorMessage(brand: string, importerCode: string, modelCode: String, modelYear: number): boolean{
    if(this.whiteListData){

      const filterItem = this.whiteListData.filter(whiteList => (whiteList.make_code == brand)
      && whiteList.importer_number == importerCode
      && modelCode == whiteList.model_code
      && (modelYear >= whiteList.model_year_start && modelYear <= whiteList.model_year_end))

      if (filterItem.length) {
        const literatureDocument = this.ownersLiteratureArray.find(literature => literature.docType === CONST_ABRIDGE_OWNER_DOCTYPE);
          literatureDocument.errorMessage = 'SEARCH.NO_ABRIDGED_OWNERS_ERROR_MESSAGE';
          literatureDocument.isLoaded = true;
          literatureDocument.isError = true;
        return true;
      }
    }
    return false;
  }*/

  isWhiteListModelDisplayBatteryLink(brand: string, importerCode: string, modelCode: String, modelYear: number): boolean{
    if(this.whiteListData){

      const filterItem = this.whiteListData.filter(whiteList => (whiteList.make_code == brand)
      && whiteList.importer_number == importerCode
      && modelCode == whiteList.model_code
      && (modelYear >= whiteList.model_year_start && modelYear <= whiteList.model_year_end))

      if (filterItem.length) {
        const literatureDocument = this.ownersLiteratureArray.find(literature => literature.docType === CONST_BATTERY_CHARGING_CABLE_MANUAL);
          //literatureDocument.errorMessage = 'SEARCH.NO_BATTERY_CHARGING_CABLE_MANUAL_MESSAGE';
          if(literatureDocument) {
            literatureDocument.isDataAvailable = true;
            //AMS : RR19028496 - igital Owner's Literature - Battery Charging Cable URL Needs to updated
            // literatureDocument.url = 'https://m.box.com/shared_item/https%3A%2F%2Fsimplepart.box.com%2Fs%2F6u40gr8c3y8edc3wv8iuhpzjq55c8u7q'
            literatureDocument.url = 'https://simplepart.app.box.com/s/6u40gr8c3y8edc3wv8iuhpzjq55c8u7q';
            literatureDocument.isLoaded = true;
            literatureDocument.isError = false;
          }
        return true;
      }
    }
    return false;
  }

  updateEmissionPartsLinks(modelYear: number, modelCode: string) {
    const emissionsLiterature = this.ownersLiteratureArray.find(literature => literature.docType === CONST_CA_EMISSIONS_DOCTYPE);
    const emissionPartsLiterature = this.ownersLiteratureArray.find(literature => literature.docType === CONST_CA_EMISSIONS_PARTS_DOCTYPE);
    // if (userImporterCode === CONST_IMPORTER_CODE_CA) {
    //   emissionsLiterature.isDataAvailable = false;
    //   emissionsLiterature.url = '';
    //   return;
    // }
    const emissionFilterList = this.cloudService.getEmissionPartsListFilter();
    emissionFilterList['modelCodes'].find(item => {
      if (item === modelCode) {
        emissionsLiterature.isDataAvailable = false;
        emissionsLiterature.errorMessage = '';
        emissionPartsLiterature.isDataAvailable = false;
        this.isElectricVehicle = true;
      }
    });
    emissionFilterList['modelYears'].find((item) => {
      if (item === modelYear) {
        emissionsLiterature.isDataAvailable = false;
        emissionsLiterature.errorMessage = '';
        emissionPartsLiterature.isDataAvailable = false;
      }
    });
  }

  getOrderLiteratureUrl() {
    const literatureDocument = this.ownersLiteratureArray.find(literature => literature.docType === CONST_ORDER_LITERATURE_DOCTYPE);
    return (!_.isNil(literatureDocument) && !_.isNil(literatureDocument.url)) ? literatureDocument.url : '';
  }

  checkDocsAvailabilityForDisclaimer() {
    let isDocsAvailable = true;
    const literatureResponse = this.getLiteratureResponse();
    if (!_.isNil(literatureResponse) && !_.isNil(literatureResponse.data)) {
      if (!literatureResponse.isError) {
        let literatureResponseCount = +literatureResponse.data.length;
        let docsAvailabilityCount = 0;
        literatureResponse.data.forEach(response => {
          if (response['docType'] === CONST_ORDER_LITERATURE_DOCTYPE || response['docType'] === CONST_OWNERS_MANUAL_DOCTYPE ||
            response['docType'] === CONST_CA_EMISSIONS_PARTS_DOCTYPE) {
            literatureResponseCount = literatureResponseCount - 1;
          } else {
            docsAvailabilityCount = (response['isDataAvailable']) ? (docsAvailabilityCount + 1) : docsAvailabilityCount;
          }
        });
        isDocsAvailable = (docsAvailabilityCount !== 0) ? true : false;
      }
    }
    return isDocsAvailable;
  }

  // updateOrderLiteratureLink(brand: string) {
  //   const literatureDocument = this.ownersLiteratureArray.find(literature => literature.docType === CONST_ORDER_LITERATURE_DOCTYPE);
  //   literatureDocument.isDataAvailable = true;
  //   literatureDocument.url = (brand == 'V') ? URL_ORDER_OWNERS_LITERATURE_VW : URL_ORDER_OWNERS_LITERATURE_AUDI;
  // }

  updateOwnersManualLink(brand: string, modelYear: number, vin: string, importerCode: string, language: string) {
    const literatureDocument = this.ownersLiteratureArray.find(literature => literature.docType === CONST_OWNERS_MANUAL_DOCTYPE);
    if ((brand == 'V' && modelYear >= 2012) || (brand == 'A' && modelYear >= 2008)) {
      literatureDocument.isDataAvailable = true;
      literatureDocument.isLoaded = true;
      literatureDocument.isError = false;
      const queryParam = (!_.isNil(vin) && vin.length > 0) ? ('?vin=' + vin) : '';
      if (importerCode === CONST_IMPORTER_CODE_US) {
        literatureDocument.url = ((brand == 'V') ? URL_OWNERS_MANUAL_VW : URL_US_OWNERS_MANUAL_AUDI) + queryParam;
      }else{
        literatureDocument.url = ((brand == 'V') ? URL_CANADA_OWNERS_MANUAL_VW : language == 'en' ? URL_CANADA_OWNERS_MANUAL_AUDI_EN : URL_CANADA_OWNERS_MANUAL_AUDI_FR) + queryParam;
      }

    }
  }

  clearVariables() {
    this.isVehicleInContext = false;
    this.ownersLiteratureArray = [];
    this.isElectricVehicle = false;
    this.vehicleContext = null;
    this.vehicleDetailsResponse = [];
    this.timeBasedServices = [];
    this.maintenanceCardUrl = '';
  }

}
